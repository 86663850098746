import { DocumentNode, gql, useQuery } from "@apollo/client";
import { GraphQlQueryResponse } from "../../general/GraphQlQueryResponse";




function getQuery(): DocumentNode {
  return gql`
  query {
    LastLiquidityScanTime
  }`
}



export function useLatestLiquidityScanDate(): GraphQlQueryResponse<Date> {

  const QUERY = getQuery();
  const query = useQuery(QUERY);
  const date = query.data?.LastLiquidityScanTime;

  return {
    loading: query.loading,
    error: query.error,
    data: new Date(date),
    refetch: query.refetch
  };
}