import { DocumentNode, gql, useQuery } from "@apollo/client";
import { useUserPlan } from "../auth/plans/useUserPlan";
import { GraphQlQueryResponse } from "../general/GraphQlQueryResponse";
import { INodeInfo, NodeInfoFragment } from "./INodeInfo";



function getQuery(isSubscribed: boolean): DocumentNode {
  return gql`
  ${NodeInfoFragment(isSubscribed)}

  query nodeInfo($id: String!) {
    NodeInfo(id: $id) {
      ...NodeInfoFragment
    }
  }`
}



export function useNodeInfo(nodeId: string, options = {skip: false}): GraphQlQueryResponse<INodeInfo> {
  const userPlan = useUserPlan();
  const QUERY = getQuery(userPlan.plan === 'SUBSCRIBED');
  const query = useQuery(QUERY, { 
    variables: { id: nodeId }, 
    skip: options.skip
   }
    );
  const nodeInfo = query.data?.NodeInfo;

  return {
    loading: query.loading,
    error: query.error,
    data: nodeInfo,
    refetch: query.refetch
  };
}