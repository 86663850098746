import Link from 'next/link';
import * as React from 'react'
import Logo from './Logo';
import EmailIcon from '@mui/icons-material/Email';
import env from '../../lib/env';
import LatestLiquidityScanDate from '../node/latestLiquidityScan/LatestLiquidityScanDate';


type Props = {
  topMargin: boolean
}

const Footer: React.FC<Props> = ({ topMargin }) => {



  return (
    <footer className={" bg-viking-1000 dark:bg-viking-1100 text-viking-50"}>
      <div className="pt-16 pb-12">

        <div className="flex justify-between flex-wrap px-2 md:px-12 ">
          <div className="">
            <a href="#" className="cursor-pointer flex items-center hover:text-viking-200">
              <Logo size="20px"></Logo> <span className="text-lg pl-2">LnRouter</span>
            </a>

            <div className='pt-6 text-gray-400 text-xs'>
              <div>
                Latest scan: <LatestLiquidityScanDate />
              </div>
              <div>
                Scan interval: Every month once.
              </div>
            </div>
          </div>


          <div className="text-sm flex flex-col items-center">
            <div className="hover:text-viking-200">
              <Link href="/about">About</Link>
            </div>

            <div className="hover:text-viking-200">
              <Link href="/terms-and-conditions"><a
                target="_blank">Terms and conditions</a></Link>
            </div>

            <div className='text-xs pt-4 text-center'>
            <div className="hover:text-viking-200">
                <Link href="/node-ranking">
                  <a>
                    Node Ranking
                  </a>
                </Link>
              </div>
              <div className="hover:text-viking-200">
                <Link href="/liquidity-ads">
                  <a>
                    Liquidity Marketplace
                  </a>
                </Link>
              </div>
              <div className="hover:text-viking-200">
                <Link href="/scores/terminal">
                  <a>
                    Terminal Score Debugger
                  </a>
                </Link>
              </div>
              <div className="hover:text-viking-200">
                <Link href="/get-route">
                  <a>
                    Shortest path
                  </a>
                </Link>
              </div>
              <div className="hover:text-viking-200">
                <Link href="/graph">
                  <a>
                    Graph
                  </a>
                </Link>
              </div>
              <div className="hover:text-viking-200">
                <Link href="/channel-id-converter">
                  <a>
                    Channel ID converter
                  </a>
                </Link>
              </div>
              <div className="hover:text-viking-200">
                  <a href="https://blog.lnrouter.app/" target="_blank">
                    Blog
                  </a>
              </div>
            </div>
          </div>
          <div>
            <div>
              <a
                href="https://twitter.com/lnrouter"
                target="_blank"
                rel="noopener noreferrer"
                className=" hover:text-viking-200"
              >
                <svg className="w-5 h-5 inline mr-2 text-twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72"><path fill="none" d="M0 0h72v72H0z" /><path fill="currentColor" d="M68.812 15.14c-2.348 1.04-4.87 1.744-7.52 2.06 2.704-1.62 4.78-4.186 5.757-7.243-2.53 1.5-5.33 2.592-8.314 3.176C56.35 10.59 52.948 9 49.182 9c-7.23 0-13.092 5.86-13.092 13.093 0 1.026.118 2.02.338 2.98C25.543 24.527 15.9 19.318 9.44 11.396c-1.125 1.936-1.77 4.184-1.77 6.58 0 4.543 2.312 8.552 5.824 10.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163 0 6.345 4.513 11.638 10.504 12.84-1.1.298-2.256.457-3.45.457-.845 0-1.666-.078-2.464-.23 1.667 5.2 6.5 8.985 12.23 9.09-4.482 3.51-10.13 5.605-16.26 5.605-1.055 0-2.096-.06-3.122-.184 5.794 3.717 12.676 5.882 20.067 5.882 24.083 0 37.25-19.95 37.25-37.25 0-.565-.013-1.133-.038-1.693 2.558-1.847 4.778-4.15 6.532-6.774z" /></svg>
                Twitter
              </a>
            </div>
            <div>
              <a
                onClick={() => window.open("https://t.me/+J22JUmgOGM81MWM0")}
                target="_blank"
                rel="noopener noreferrer"
                className=" hover:text-viking-200 cursor-pointer"
              >
                <svg className="w-6 h-6 inline mr-1 fill-current text-twitter"
                  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="100 150 800 800" version="1.1">
                  <g id="Artboard" stroke="none" strokeWidth="1" fillRule="evenodd">
                    <path d="M226.328419,494.722069 C372.088573,431.216685 469.284839,389.350049 517.917216,369.122161 C656.772535,311.36743 685.625481,301.334815 704.431427,301.003532 C708.567621,300.93067 717.815839,301.955743 723.806446,306.816707 C728.864797,310.92121 730.256552,316.46581 730.922551,320.357329 C731.588551,324.248848 732.417879,333.113828 731.758626,340.040666 C724.234007,419.102486 691.675104,610.964674 675.110982,699.515267 C668.10208,736.984342 654.301336,749.547532 640.940618,750.777006 C611.904684,753.448938 589.856115,731.588035 561.733393,713.153237 C517.726886,684.306416 492.866009,666.349181 450.150074,638.200013 C400.78442,605.66878 432.786119,587.789048 460.919462,558.568563 C468.282091,550.921423 596.21508,434.556479 598.691227,424.000355 C599.00091,422.680135 599.288312,417.758981 596.36474,415.160431 C593.441168,412.561881 589.126229,413.450484 586.012448,414.157198 C581.598758,415.158943 511.297793,461.625274 375.109553,553.556189 C355.154858,567.258623 337.080515,573.934908 320.886524,573.585046 C303.033948,573.199351 268.692754,563.490928 243.163606,555.192408 C211.851067,545.013936 186.964484,539.632504 189.131547,522.346309 C190.260287,513.342589 202.659244,504.134509 226.328419,494.722069 Z" id="Path-3" />
                  </g>
                </svg>
                Telegram
              </a>
            </div>
            <div>
              <a
                href="mailto:support@lnrouter.app"
                target="_blank"
                rel="noopener noreferrer"
                className="  hover:text-viking-200 flex items-center"
              >
                <EmailIcon fontSize="inherit" color="inherit" className="text-twitter mr-2 ml-1" />
                Email
              </a>
            </div>
          </div>

        </div>
      </div>
      {env?.lnrouter_env !== 'production' &&
        <div className="text-center text-xs">Environment: {env.lnrouter_env}</div>
      }

    </footer>
  );
}

export default Footer;

