import { useAuth } from 'reactfire';
import * as React from 'react'
import { createContext } from "react";


export const FirebaseAuthReadyContext = createContext<boolean>(false);


type Props = {
    children: any
}

const FirebaseAuthReadyProvider = ({ children }: Props) => {
    const firebaseAuth = useAuth();
    const [isAuthInitialized, setIsAuthInitialized] = React.useState<boolean>(false);
  
    React.useEffect(() => {
      if (!firebaseAuth) {
        return;
      }
      // As soon as the user is set for the first time Firebase is ready.
      let unsub = () => { };
      unsub = firebaseAuth.onAuthStateChanged(user => {
        setIsAuthInitialized(true);
        unsub();
      })
      return () => {
        unsub();
      }
    }, [firebaseAuth]);
  
    return (
        <FirebaseAuthReadyContext.Provider value={isAuthInitialized}>
            {children}
        </FirebaseAuthReadyContext.Provider>
    );
};

export default FirebaseAuthReadyProvider;


export function useIsFirebaseAuthReady() {
    return React.useContext(FirebaseAuthReadyContext)
}