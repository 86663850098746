import { useAuth, useUser } from 'reactfire';
import firebase from 'firebase/app';
import "firebase/auth";
import { useIsFirebaseAuthReady } from './FirebaseAuthReadyProvider';
import client from '../../lib/graphql';

export class LnRouterAuth {
  constructor(
    public auth: firebase.auth.Auth,
    public user: firebase.User,
    public loading: boolean,
  ) { }

  get isLoggedIn(): boolean {
    return !!this.user && !this.user?.isAnonymous;
  }

  get uid(): string {
    return this.user?.uid;
  }

  get isEmailAdded() {
    return !!this.user?.email;
  }

  async logout() {
    try {
      await this.auth?.signOut()
      this.clearFirestoreCache();
      await client.resetStore();
    } catch (e) {
      console.error('Error logout', e);
    }
  }

  private clearFirestoreCache = () => {
    const map = globalThis['_reactFirePreloadedObservables'];
    Array.from(map.keys()).forEach(
      (key: string[]) => key.includes('firestore') && map.delete(key),
    );
  };

  async setEmailAddress(email: string) {
    await this.user.updateEmail(email);
  }
}



export function useLnRouterAuth(): LnRouterAuth {
  const firebaseInitialized = useIsFirebaseAuthReady();
  const auth = useAuth();
  const user = useUser();
  return new LnRouterAuth(auth, user.data, !firebaseInitialized);
}
