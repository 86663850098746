import { Button } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react'



interface Props {
    children: React.ReactNode,
    path: string
    padding?: 'normal' | 'big',
    underlineMatch?: 'partial' | 'perfect',
    isBeta?: boolean,
}

const MenuItem: React.FC<Props> = ({ children, path, padding = 'normal', underlineMatch = 'perfect', isBeta = false }: Props) => {
    const router = useRouter();
    let currentPathMatches = false;
    if (underlineMatch === 'partial') {
        currentPathMatches = router.pathname.includes(path);
    } else if (underlineMatch === 'perfect') {
        currentPathMatches = router.pathname === path;
    }
    const paddingClasses = padding === 'normal' ? 'px-2 py-2' : 'px-8 py-4';
    const classes = currentPathMatches ? 'underline ' : '';
    return (
        <Link href={path} passHref={true} >
            <a className="inline-block hover:bg-gray-100 dark:hover:bg-gray-800">
                <div className={paddingClasses + " inline-block text-sm rounded font-medium cursor-pointer light:text-gray-700 "}
                    style={{ zIndex: 1301 }}
                >
                    <span className={classes + ' normal-case'}>{children}</span>
                    {isBeta &&
                        <span style={{ lineHeight: '12px' }}
                            className="ml-1 align-top text-[8px] text-yellow-600">
                            Beta
                        </span>
                    }

                </div>
            </a>
        </Link>

    );
}

export default MenuItem;

