import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navigation from "./Navigation";
import { createTheme, ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import MemoryUsage from "./MemoryUsage";
import SimpleNodeListProvider from "../general/SimpleNodeListProvider";
import { useGeneralSettings } from "../general/maintenance/GeneralSettingsProvider";
import { useRouter } from "next/router";
import FirebaseAuthReadyGuard from "../auth/FirebaseAuthReadyGuard";
import FirebaseAuthReadyProvider from "../auth/FirebaseAuthReadyProvider";
import { ToastContainer } from 'react-toastify';
import DarkModeProvider from "./darkMode/DarkModeProvider";



declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}




type Props = {
  children: any,
  pageTitle?: string,
  seoDescription?: string,
  shareDescription?: string,
  disableOnMaintenance?: boolean,
  bottomMargin?: boolean,
  className?: string,
  showAdvertisement?: boolean,
  disableEmailConfirmationAd?: boolean,
  customOpenGraph?: React.ReactNode
}

const vikingGreen = {
  main: '#27A3A4',
  light: '#4adddf',
}

const black = {
  main: '#232934',
}

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: vikingGreen,
    secondary: black
  }
}));

const BaseLayout = ({ children, pageTitle, seoDescription, shareDescription = seoDescription, 
  disableOnMaintenance = false, bottomMargin = true,
  showAdvertisement=true, disableEmailConfirmationAd=false, customOpenGraph }: Props) => {
  const generalSettings = useGeneralSettings();
  const router = useRouter()
  const isQueryTest = !!(router.query.test as string);
  const [isTest, setIsTest] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isQueryTest) {
      localStorage.setItem('disableMaintenance', '1');
    }
    const disable = localStorage.getItem('disableMaintenance');
    if (!!disable) {
      setIsTest(true);
    }
  }, [router.query]);



  function disableMaintenanceTest() {
    localStorage.removeItem('disableMaintenance');
    setIsTest(false);
  }

  const classes = bottomMargin ? 'pb-48' : '';
  const showMaintenance = (generalSettings.isMaintenance && disableOnMaintenance) && !isTest;
  return (
    <FirebaseAuthReadyProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {/* <SimpleNodeListProvider> */}
            <div className="relative">
              <div className="sticky inline-block top-0 right-0 float-right z-10">
                <div className="mt-12">
                  <MemoryUsage />
                </div>

              </div>
              <ToastContainer position="bottom-right" />
              <Header pageTitle={pageTitle} seoDescription={seoDescription} shareDescription={shareDescription} customOpenGraph={customOpenGraph} />
              <main className="">
                <FirebaseAuthReadyGuard>
                  <Navigation showAdvertisement={showAdvertisement} disableEmailConfirmationAd={disableEmailConfirmationAd} />
                  <div className={classes + " relative bg-[#F8FBFF] dark:bg-black dark:text-gray-50"} style={{ minHeight: '70vh' }}>
                    {isTest &&
                      <div className="bg-red-500 text-center text-white">
                        Maintenance test <button
                          className="border my-1 px-1 rounded hover:bg-red-700"
                          onClick={disableMaintenanceTest}
                        >Disable test</button>
                      </div>
                    }
                    {showMaintenance &&
                      <div className="absolute top-0 w-full h-full bg-white z-10">
                        <div className="flex flex-col items-center h-full mb-16 mt-36 py-12">
                          <p className="text-center bg-viking-500 px-12 py-1  font-black italic">Maintenance in progress...</p>
                          <p className="text-gray-500 text-sm mt-2">We will be back soon.</p>
                        </div>
                      </div>
                    }
                    {!showMaintenance &&
                      children
                    }
                  </div>
                </FirebaseAuthReadyGuard>
              </main>
              <Footer topMargin={bottomMargin} />
            </div>
          {/* </SimpleNodeListProvider> */}
        </ThemeProvider>
      </StyledEngineProvider>
    </FirebaseAuthReadyProvider>
  );
}

export default BaseLayout;