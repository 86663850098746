import * as React from 'react'
import Logo from '../layout/Logo';

import { useLnRouterUser } from './useLnRouterUser';


export type AuthGuardMustBe = 'authenticated' | 'unauthenticated';

type Props = {
  children,
}

const FirebaseAuthReadyGuard: React.FC<Props> = ({ children }: Props) => {
  const user = useLnRouterUser()

  const loadingScreen = <div className="flex items-center justify-center " style={{ minHeight: '80vh' }}>
    <div className="flex flex-col items-center">
      <div>
        <Logo size={'40px'} className="inline-block pb-1" /> <span className="text-2xl pl-2">LnRouter</span>
      </div>
      <p className="text-sm text-gray-600 mt-2">Loading the network</p>
    </div>
  </div>;


  if (user.loading) {
    return loadingScreen;
  }

  return children;
}

export default FirebaseAuthReadyGuard;

