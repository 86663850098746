import { useRouter } from 'next/router';
import * as React from 'react'
import { useLnRouterAuth } from './useLnRouterAuth';


export type AuthGuardMustBe = 'authenticated' | 'unauthenticated';

type Props = {
  children,
  mustBe?: AuthGuardMustBe,
  redirect?: boolean,
  redirectTo?: string,
}

const AuthGuard: React.FC<Props> = ({ children, mustBe, redirect=false, redirectTo="/" }: Props) => {
  const auth = useLnRouterAuth();
  const router = useRouter();

  function shouldShowContent(): boolean {
    if (!mustBe) {
      return true;
    }else if (mustBe === 'authenticated' && auth.isLoggedIn) {
      return true;
    } else if (mustBe === 'unauthenticated' && !auth.isLoggedIn) {
      return true;
    }
    return false;
  }

  const showContent =  shouldShowContent();

  React.useEffect(() => {
    if (!showContent && redirect) {
      router.push(redirectTo);

    }

  }, [auth]);

  return (
    <React.Fragment>
      {(showContent) &&
        children
      }
    </React.Fragment>
  );
}

export default AuthGuard;

