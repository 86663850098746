import Link from 'next/link';
import * as React from 'react';
import { useLnRouterUser } from './useLnRouterUser';


type Props = {
  children: React.ReactNode
}


const MyNodeLink = ({ children }: Props) => {
  const user = useLnRouterUser();
  const myNodeId = user.data?.nodeId;

  if (!myNodeId) {
    return <React.Fragment />;
  }

  return (
    <Link href={`/node/${myNodeId}`}>
      {children}
    </Link>
  );
};

export default MyNodeLink;