import * as React from 'react'
import "firebase/auth";
import { useLnRouterAuth } from './useLnRouterAuth';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { GraphQlQueryResponse } from '../general/GraphQlQueryResponse';
import { useIsFirebaseAuthReady } from './FirebaseAuthReadyProvider';

export interface IUserPlan {
  id: string,
  start: string,
  end: string,
  description: string,
  invoiceNumber: string,
  amountSat: number,
  createdAt: string
}

export interface ILnRouterUser {
  uid: string,
  nodeId: string,
  email?: string,
  emailActivated: boolean,
  isAdmin: boolean,
  isBetaUser: boolean,
  balanceSat: number,
  trailAvailable: boolean,
  plans: IUserPlan[]
}


const QUERY = gql`
query {
  userInfo {
    id
    uid
    createdAt
    nodeId
    email
    emailActivated
    isAdmin
    isBetaUser
    balanceSat
    trailAvailable
    plans {
      id
      start
      end
      description
      invoiceNumber
      amountSat
      createdAt
    }
  }
}
`;

export function useLnRouterUser(): GraphQlQueryResponse<ILnRouterUser> {
  const auth = useLnRouterAuth();
  const [user, setUser] = React.useState<ILnRouterUser>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);

  const [fetch, query] = useLazyQuery(QUERY);

  React.useEffect(() => {
    if (auth.loading) {
      return;
    }
    if (auth?.uid) {
      fetch({variables: { uid: auth.uid}});
    } else {
      setLoading(false);
      setUser(undefined);
    }
  }, [auth.loading, auth?.uid]);

  React.useEffect(() => {
    if (query.data) {
      setUser(query.data?.userInfo);
      setLoading(false)
    }
  }, [query.data]);

  const ret = {
    loading: query.loading || loading,
    error: query.error,
    data: user,
    refetch: query.refetch
  }
  return ret;
}
