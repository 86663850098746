import * as React from 'react'
import { useLatestLiquidityScanDate } from './useLatestLiquidityScanDate';




type Props = {

}

const LatestLiquidityScanDate: React.FC<Props> = ({  }) => {
  const date = useLatestLiquidityScanDate()

  return (
    <span>
        {date.data?.toLocaleDateString()}
    </span>
  );
}

export default LatestLiquidityScanDate;

