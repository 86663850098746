import * as React from 'react'
import Link from 'next/link'

import Logo from './Logo';
import MenuItem from './MenuItem';
import DropDownMenuItem from './DropDownMenuItem';
import AuthMenu from './AuthMenu';
import AuthGuard from '../auth/AuthGuard';
import ActionButton from '../general/ActionButton';
import NavAdvertisement from './NavAdvertisement';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import EmailConfirmationAd from '../auth/email/EmailConfirmationAd';
import LooksOneIcon from '@mui/icons-material/LooksOne';

type Props = {
    showAdvertisement: boolean,
    disableEmailConfirmationAd?: boolean
}

const Navigation: React.FC<Props> = ({ showAdvertisement, disableEmailConfirmationAd=false }: Props) => {
    return (
        <div className="border-b dark:border-viking-800 dark:bg-viking-1100 dark:text-white">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center pt-6 pb-4 ">
                <div className="flex justify-center px-1">
                    <Link href="/">
                        <div className="cursor-pointer flex items-center">
                            <Logo></Logo> <span className="text-2xl pl-2">LnRouter</span>
                        </div>
                    </Link>
                </div>
                <div className="md:flex justify-end px-1 pt-2 md:pt-0">
                    <div className="flex flex-wrap justify-center items-center font-sans">
                        <MenuItem path="/explore">
                            Explore
                        </MenuItem>

                        <DropDownMenuItem title={<span className='font-sans'>Tools</span>} path="" useUnderline={false}>
                        <MenuItem padding="big" path="/channel-recommender-preview" underlineMatch="partial">
                                <AccountTreeIcon fontSize="inherit" color="inherit" className="mr-2 text-xl" />
                                Channel Recommender
                            </MenuItem>
                            <MenuItem padding="big" path="/node-ranking" underlineMatch="partial">
                                <LooksOneIcon fontSize="inherit" color="inherit" className="mr-2 text-xl" />
                                Node Ranking
                            </MenuItem>
                            <MenuItem padding="big" path="/liquidity-ads" underlineMatch="partial">
                                <StorefrontIcon fontSize="inherit" color="inherit" className="mr-2 text-xl" />
                                Liquidity Ad Marketplace
                            </MenuItem>
                            <MenuItem padding="big" path="/scores/terminal" underlineMatch="partial">
                                <span className="pr-7 text-lg"></span>
                                Terminal Score Debugger
                            </MenuItem>
                            <MenuItem padding="big" path="/get-route" underlineMatch="partial">
                                <span className="pr-7 text-lg"></span>
                                Shortest path
                            </MenuItem>
                            <MenuItem padding="big" path="/channel-id-converter" underlineMatch="partial">
                                <span className="pr-7 text-lg"></span>
                                Channel ID converter
                            </MenuItem>
                        </DropDownMenuItem >
                        <DropDownMenuItem title={<span className='font-sans'>Graphs</span>} path="/graph">
                            <MenuItem padding="big" path="/graph">Lightning network</MenuItem>
                            <MenuItem padding="big" path="/graph/zero-base-fee">#zeroBaseFee</MenuItem>
                            <MenuItem padding="big" path="/graph/betweenness-centrality">Betweenness centrality</MenuItem>
                        </DropDownMenuItem >
                        <div className='pr-2'></div>

                        <AuthGuard mustBe="authenticated">
                            <AuthMenu />
                        </AuthGuard>

                        <AuthGuard mustBe="unauthenticated">
                            <Link href="/auth/login">
                                <a>
                                    <ActionButton design="default">
                                        <span className="capitalize">
                                            Sign in
                                        </span>
                                    </ActionButton>
                                </a>
                            </Link>
                        </AuthGuard>
                    </div>

                </div>
            </div>
            {!disableEmailConfirmationAd &&
            <EmailConfirmationAd />
            }
            
            {/* {showAdvertisement &&
                <NavAdvertisement />
            } */}

        </div>


    );
}

export default Navigation;

