import Link from 'next/link';
import * as React from 'react'
import { useLnRouterUser } from '../useLnRouterUser';


const EmailConfirmationAd: React.FC = ({ }) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const user = useLnRouterUser();

    React.useEffect(() => {
        if (!user.data || user.data.emailActivated) {
            setVisible(false);
        } else {
            setVisible(true);
        }
    }, [user.data]);


    if (!visible) {
        return <React.Fragment />
    }
    return (
        <div className='bg-yellow-400 flex justify-center items-center  pr-2'>
            <Link href="/account/profile?changeEmail=1" scroll={false}>
                <a className='text-center hover:text-viking-800 cursor-pointer text-sm'>
                    Confirm email address.
                </a>
            </Link>
        </div >

    );
}

export default EmailConfirmationAd;

