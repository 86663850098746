import * as React from 'react'
import { useNodeInfo } from '../node/useNodeInfo';



type Props = {
  nodeId: string
}

const NodeLabel = ({ nodeId }: Props) => {
  const info = useNodeInfo(nodeId);
  const node = info.data;

  if (!nodeId) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <React.Fragment>
      {node && node.label}
      {!node && nodeId.slice(0,12)}
    </React.Fragment>
  );
};

export default NodeLabel;