import * as React from 'react'
import { Button, ButtonProps } from '@mui/material';
import Loader from "react-loader-spinner";


export interface Props extends ButtonProps {
    isLoading?: boolean
    className?: string,
    design?: "default" | "action" | "secondary" | "tertiary",
}

const ActionButton: React.FC<Props> = ({isLoading=false, className='', design="default", ...rest}: Props & ButtonProps) => {
    const defaultClasses = 'bg-viking-700';
    const actionClasses = 'bg-rose-600 hover:bg-rose-700';
    const secondaryClasses = 'bg-transparent text-black hover:text-white hover:bg-black border border-black hover:border-black';
    const tertiaryClasses = 'bg-transparent text-black hover:text-white hover:bg-black border border-white';

    let variant = 'contained';
    let classes = '';
    switch (design) {
        case "action": {
            variant = 'contained';
            classes = actionClasses;
            break;
        }
        case "secondary": {
            variant = 'outlined';
            classes = secondaryClasses;
            break;
        }
        case "tertiary": {
            variant = 'contained';
            classes = tertiaryClasses;
            break;
        }
        default: {
            variant = 'contained';
            classes = defaultClasses;
            break;
        }
    }

    return (
        <Button 
        onClick={(e) => {
            if (isLoading) {
                return;
            }
            if (rest.onClick ) {
                rest.onClick(e);
            }
        }}
        className={`${classes} ${className}`} color="primary" variant={variant as any}
        {...rest} 
        disabled={rest.disabled || isLoading}
            disableElevation={true}>
            {isLoading &&
                <Loader color="#9ca3af" height="24px" type="ThreeDots" width="40px" />
            }

            {!isLoading &&
            rest.children
                
            }
        </Button>
    );
}

export default ActionButton;


