
interface INodeInfoOutputConfig {
  objectType: string,
  fragmentName: string
}

export interface INodeInfo {
  id: string;
  label: string;
  channelCount: number;
  capacitySat: number;
  medianPpm: number;
  bosScore: number;
  terminalScore: number;
  terminalRank: number;
  ipAddress?: string;
  torAddress?: string;
  features: INodeFeatures;
  liquidityAd?: INodeLiquidityAd;
  liquidity?: INodeLiquidity;
  geolocation?: INodeGeolocation;
  avgChannelDistanceKm?: number;
  inactiveIncomingChannelsPercentage: number;
  medianChannelCapacitySat: number;
  avgChannelCapacitySat: number;
  closenessCentrality: number,
  closenessCentralityPercentile: number,
  lastUpdate: string;
}

export interface INodeLiquidityAd {
  leaseFeeBaseMsat: number;
  leaseFeeBasis: number;
  fundingWeight: number;
  channelFeeMaxBaseMsat: number;
  channelFeeMaxProportionalThousandths: number;
}

export interface INodeFeatures {
  zeroBaseFee: boolean,
  liquidityScan: boolean
}

export interface INodeGeolocation {
  id: string,
  city: string,
  country: string,
}

export interface INodeLiquidity {
  inOutboundRatio: number;
  balancedPercent: number;
  balancedPercentPercentile: number;
  avgOnionResponseTime: number;
  avgOnionResponseTimePercentile: number;
  bestThirdOnionResponseTime: number;
  adjustedClosenessCentrality: number;
  adjustedClosenessCentralityPercentile: number;
  routingScore: number;
  routingScorePercentile: number;
  routingScoreRank: number;
  scannedAt: string
}


export function NodeInfoFragment(isSubscribed: boolean, config: INodeInfoOutputConfig = {
  objectType: 'NodeInfoOutput',
  fragmentName: 'NodeInfoFragment'
}) {
  const subscriberFields = `
    liquidity {
      inOutboundRatio
      balancedPercent
      balancedPercentPercentile
      avgOnionResponseTime
      avgOnionResponseTimePercentile
      bestThirdOnionResponseTime
      adjustedClosenessCentrality
      adjustedClosenessCentralityPercentile
      routingScore
      routingScorePercentile
      routingScoreRank
      scannedAt
    }`;
  return `
  
  fragment ${config.fragmentName} on ${config.objectType}{
      id
      label
      channelCount
      capacitySat
      medianPpm
      medianChannelCapacitySat
      closenessCentrality
      closenessCentralityPercentile
      ipAddress
      torAddress
      inactiveIncomingChannelsPercentage
      avgChannelCapacitySat
      lastUpdate
      avgChannelDistanceKm
      features {
        zeroBaseFee
        liquidityScan
      }
      geolocation {
        id
        country
        city
      }
      liquidityAd {
        leaseFeeBaseMsat
        leaseFeeBasis
        fundingWeight
        channelFeeMaxBaseMsat
        channelFeeMaxProportionalThousandths
      }
      ${isSubscribed ? subscriberFields : ''}
  }`
}
