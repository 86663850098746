import { useRouter } from 'next/router';
import * as React from 'react'
import { useLnRouterUser } from './useLnRouterUser';


export type UserTypeGuardMustBe = 'admin' | 'betaUser';

type Props = {
  children,
  mustBe?: UserTypeGuardMustBe,
  redirect?: boolean
}

const UserTypeGuard: React.FC<Props> = ({ children, mustBe, redirect=false }: Props) => {
  const user = useLnRouterUser();
  const router = useRouter();

  function shouldShowContent(): boolean {
    if (!mustBe) {
      return true;
    } else if (mustBe === 'admin' && user.data?.isAdmin) {
        return true;
      } else if (mustBe === 'betaUser' && (user.data?.isAdmin || user.data?.isBetaUser)) {
        return true;
      } 
    return false;
  }

  const showContent = shouldShowContent();

  React.useEffect(() => {
    if (user.loading) {
      return;
    }
    if (!showContent && redirect) {
      console.log('Redirect UserTypeGuard')
      router.push('/');
    }

  }, [user.loading]);

  return (
    <React.Fragment>
      {showContent &&
        children
      }
    </React.Fragment>
  );
}

export default UserTypeGuard;

