
import React from "react";
import BaseLayout from "./BaseLayout";
import NumberFormat from "react-number-format";
import TextContainer from "./TextContainer";
import { useRouter } from "next/router";



export default function MemoryUsage() {
  const router = useRouter();
  const { memory } = router.query;
  const [heapLimit, setHeapLimit] = React.useState<number>(-1);
  const [currentUse, setCurrentUse] = React.useState<number>(-1);


  function readCurrentUse(alerting=false) {
    try {
      const use = (performance as any).memory.usedJSHeapSize;
      setCurrentUse(use);
      if (alerting) {
        alert('Use: ' + Math.round(use/(1000*1000)) + 'MB')
      }
    } catch (e) {
      if (alerting) {
        alert('error reading current memory use ' + e);
      }
    }
  }

  React.useEffect(() => {
    try {
      const limit = (performance as any).memory.jsHeapSizeLimit;
      setHeapLimit(limit);
    } catch (e) {

    }

    const intervalId = setInterval(() => {
      readCurrentUse();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    }
  }, []);

  if (!memory) {
    return <div></div>
  }

  return (

    <div className="border-l border-b border-t bg-white rounded-l pl-2 pr-1 py-1">
      <div>
        Used: <NumberFormat displayType="text" thousandSeparator value={Math.round(currentUse / (1000 * 1000))} /> MB
        <span> / </span> <NumberFormat decimalScale={1} displayType="text" thousandSeparator value={(currentUse / heapLimit) * 100} />%
      </div>
      <div>Heap limit: <NumberFormat displayType="text" thousandSeparator value={Math.round(heapLimit / (1000 * 1000))}></NumberFormat> MB</div>
    </div>

  );
}
